import {useState, useEffect} from "react";
import {accessToken, logout, getCurrentUserProfile} from "./spotify";
import {catchErrors} from "./utils";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from "react-router-dom";
import "./App.css";
import TopArtists from "./TopArtists";
import TopTracks from "./TopTracks";
import Playlists from "./Playlists";
import Profile from "./Profile";
import SpotifyPlayer from "./components/SpotifyPlayer";
import CurrentlyPlayingCredits from "./components/CurrentlyPlayingCredits";
import Landing from "./components/Landing";
import Navbar from "./components/Navbar";

function ScrollToTop() {
	const {pathname} = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

function App() {
	const [token, setToken] = useState(null);
	const [profile, setProfile] = useState(null);
	const [currentSong, setCurrentSong] = useState(null);

	useEffect(() => {
		setToken(accessToken);

		if (token) {
			const fetchData = async () => {
				const {data} = await getCurrentUserProfile();
				setProfile(data);
			};

			catchErrors(fetchData());
		}
	}, [token]);

	const handleSongChange = (song) => {
		setCurrentSong(song);
	};

	const handleLogout = () => {
		// Clear the access token
		logout();

		// Optionally redirect the user to the login page or show a message
		window.location.href = "/"; // Uncomment this to redirect to the login page
	};

	return (
		<div className="App">
			{!token ? (
				<div className="login-container">
					<Landing />
				</div>
			) : (
				<header className="App-header">
					<Router>
						<ScrollToTop />
						<Navbar onLogout={handleLogout} /> {/* Include Navbar here */}
						<Routes>
							<Route path="/top-artists" element={<TopArtists />} exact />
							<Route
								path="/"
								element={
									<div className="main-content">
										<SpotifyPlayer
											accessToken={accessToken}
											onSongChange={handleSongChange}
										/>
										<CurrentlyPlayingCredits currentSong={currentSong} />
									</div>
								}
								exact
							/>
							<Route path="/top-tracks" element={<TopTracks />} exact />
							<Route path="/playlists/:id" element={<Playlists />} exact />
							<Route path="/playlists" element={<Playlists />} exact />
							<Route
								path="/profile"
								element={<Profile profile={profile} />}
								exact
							/>
						</Routes>
					</Router>
				</header>
			)}
		</div>
	);
}

export default App;
