import React from "react";
import Login from "./Login";
import logo from "../images/tracktracer-grey.png";
import "./Landing.css";

const Landing = () => (
	<div className="landing-container">
		<img src={logo} alt="Track Tracer Logo" className="logo" />
		<div className="login-section">
			<Login />
		</div>
		<div className="container">
			<div className="logo-section">
				<h1 className="tagline">Discover the Story Behind Every Track</h1>
				<p className="description">
					Ever wondered who the geniuses are behind your favorite songs? Track
					Tracer reveals the hidden details—producers, writers, and
					musicians—that make your music unique. Connect your Spotify account
					and start tracing the roots of your favorite tracks.
				</p>
			</div>

			<div className="features-section">
				<h2 className="features-heading">Why Track Tracer?</h2>
				<ul className="features-list">
					<li className="feature-item">
						<strong>Deep Dive into Music Credits:</strong> Uncover the unsung
						heroes behind your favorite songs.
					</li>
					<li className="feature-item">
						<strong>Personalized Insights:</strong> Explore the most influential
						producers and writers in your listening history.
					</li>
					<li className="feature-item">
						<strong>Seamless Spotify Integration:</strong> Instantly sync your
						Spotify account and start exploring.
					</li>
				</ul>
			</div>

			<div className="cta-section">
				<h2 className="cta-heading">Ready to Explore?</h2>
				<p className="cta-description">
					Sign in with Spotify to unlock a deeper understanding of the music you
					love. Whether you're a casual listener or a music aficionado, Track
					Tracer has something for you.
				</p>
			</div>
		</div>
	</div>
);

export default Landing;
