import React from "react";
import {Link} from "react-router-dom";
import logo from "../images/cropped_logo.svg";
import "./Navbar.css";

const Navbar = ({onLogout}) => {
	return (
		<nav className="navbar">
			<div className="navbar-logo">
				<Link to="/">
					<img src={logo} alt="TrackTracer Logo" />
				</Link>
			</div>
			<div className="navbar-links">
				<Link to="/profile">Profile</Link>
				<button className="logout-button" onClick={onLogout}>
					Logout
				</button>
			</div>
		</nav>
	);
};

export default Navbar;
