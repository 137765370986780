import "./Login.css";

const LOGIN_URI =
	process.env.NODE_ENV !== "production"
		? "http://localhost:8888/login"
		: "https://tracktracer.app/login";

const Login = () => (
	<div className="container">
		<div className="row">
			<div className="col-sm">
				<button
					className="btn btn-outline"
					onClick={() => (window.location.href = LOGIN_URI)}
				>
					Log in to Spotify
				</button>
			</div>
		</div>
	</div>
);

export default Login;
