import React, {useState, useEffect} from "react";
import axios from "axios";
import "./CurrentlyPlayingCredits.css";

const CurrentlyPlayingCredits = ({currentSong}) => {
	const [songName, setSongName] = useState("");
	const [artistName, setArtistName] = useState("");
	const [producers, setProducers] = useState([]);
	const [writers, setWriters] = useState([]);
	const [performancesBreakdown, setPerformancesBreakdown] = useState([]);
	const [labels, setLabels] = useState([]);
	const [showAdditionalCredits, setShowAdditionalCredits] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (currentSong) {
			getCurrentlyPlayingSongDetails();
		}
	}, [currentSong]);

	useEffect(() => {
		if (songName && artistName) {
			searchSong();
			getAdditionalPerformers();
		}
	}, [songName, artistName]);

	const searchSong = async () => {
		setLoading(true);

		try {
			const response = await axios.get("/search-song", {
				params: {
					songName,
					artistName,
				},
			});
			const {data} = response;
			if (data) {
				const {producers, writers} = data;
				setProducers(producers);
				setWriters(writers);
				setError(null);
			} else {
				setError("Failed to fetch song data. Please try again.");
			}
		} catch (error) {
			setError("Failed to fetch song data. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const getAdditionalPerformers = async () => {
		setLoading(true);

		try {
			const response = await axios.get("/get-additional-performers", {
				params: {
					songName,
					artistName,
				},
			});
			const {data} = response;
			if (data) {
				const {performancesBreakdown, labels} = data;
				setPerformancesBreakdown(performancesBreakdown);
				setLabels(labels);
				setError(null);
			} else {
				setError("Failed to fetch custom performances data. Please try again.");
			}
		} catch (error) {
			setError("Failed to fetch custom performances data. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const getCurrentlyPlayingSongDetails = () => {
		let updatedSongName = "";
		let updatedArtistName = "";

		if (currentSong.name.includes("(")) {
			updatedSongName = currentSong.name.split("(")[0];
			updatedArtistName = currentSong.artists[0].name;
		} else if (currentSong.name.includes("-")) {
			updatedSongName = currentSong.name.split("-")[0];
			updatedArtistName = currentSong.artists[0].name;
		} else {
			updatedSongName = currentSong.name;
			updatedArtistName = currentSong.artists
				.map((artist) => artist.name)
				.join(", ");
		}

		setSongName(updatedSongName);
		setArtistName(updatedArtistName);
	};

	const toggleCredits = () => {
		setShowAdditionalCredits(!showAdditionalCredits);
	};

	return (
		<div className="credits-container">
			{loading ? (
				<div className="loading-spinner">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			) : (
				<div>
					<div className="credits-row">
						{error && <p className="error-message">{error}</p>}
						{producers.length > 0 && (
							<div className="credits-card">
								<div className="credits-card-header">
									<h6 className="credits-card-title">Producers</h6>
								</div>
								<ul className="credits-list">
									{producers.map((producer, index) => (
										<li key={index}>{producer}</li>
									))}
								</ul>
							</div>
						)}
						{writers.length > 0 && (
							<div className="credits-card">
								<div className="credits-card-header">
									<h6 className="credits-card-title">Writers</h6>
								</div>
								<ul className="credits-list">
									{writers.map((writer, index) => (
										<li key={index}>{writer}</li>
									))}
								</ul>
							</div>
						)}
					</div>
					{labels.length > 0 && (
						<div className="additional-credits">
							<button
								className={`toggle-credits-btn ${
									showAdditionalCredits ? "btn-outline" : ""
								}`}
								onClick={toggleCredits}
							>
								{showAdditionalCredits
									? "Hide Additional Credits"
									: "Show Additional Credits"}
							</button>
							{showAdditionalCredits && (
								<div className="credits-row mt-3">
									{labels.map((label, index) => (
										<div className="credits-card" key={index}>
											<div className="credits-card-header">
												<h6 className="credits-card-title">{label}</h6>
											</div>
											<ul className="credits-list">
												{performancesBreakdown[label].map((value, idx) => (
													<li key={idx}>{value}</li>
												))}
											</ul>
										</div>
									))}
								</div>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default CurrentlyPlayingCredits;
