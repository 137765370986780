function Profile({profile}) {
	return (
		<div>
			<h1 className="">Profile</h1>

			{profile && (
				<div>
					<h2>{profile.display_name}</h2>
					<p>{profile.followers.total} Followers</p>
					{profile.images.length && profile.images[0].url && (
						<img src={profile.images[0].url} alt="Avatar" />
					)}
				</div>
			)}
		</div>
	);
}

export default Profile;
