function TopTracks() {
	return (
		<div>
			<div>
				<h1>Top Artists</h1>
			</div>
		</div>
	);
}

export default TopTracks;
